
    import { Component, Vue } from 'vue-property-decorator';
    import EventBus from './event-bus.js'

    import { Store } from 'vuex';
    import { IGoodFilter, IGood } from '@/interfaces';
    import { readGoodFilter, readGoods, readAllVendors, readRowsPerPage } from '@/store/main/getters';
    import { dispatchUpdateGoodFilter, dispatchGetAllVendors, dispatchUpdateRowsPerPage } from '@/store/main/actions';

@Component
export default class SearchGoods extends Vue {
    public tovar_search: string = '';
    public is_gost: boolean = false;
    public is_tu: boolean = false;
    public is_only_2month: boolean = true;
    public vendors: Array = [];

    public expand = true;
    public selectedRows = [];
    public pagination = {}
    
    public td_width = [0,0,0,0,0,0,0,0,0,0]

                                                                            // Заголовки столбцов на странице
    public headers = [
        {text: 'Наименование', value: 'title', class: 'sticky', sortable: true, align: 'left'},
        {text: 'Дата', value: 'ts', sortable: true, align: 'left',},
        {text: 'Поставщик', value: 'vendor', sortable: true, align: 'left',},
        {text: 'Email', value: 'sender', sortable: true, align: 'left',},
        {text: 'Количество', value: 'amount', sortable: true, align: 'left',},
        {text: 'Цена', value: 'price', sortable: true, align: 'left',},
        {text: 'НДС', value: 'comments', sortable: true, align: 'left',},
        {text: 'Менеджер', value: 'manager', sortable: true, align: 'left',},
        {text: 'История', value: 'plus'},
    ];
                                                                            // Заголовки столбцов для Excel
    public headers_xlsx = [
        {label: 'Наименование', field: 'title'},
        {label: 'Дата', field: 'ts'},
        {label: 'Поставщик', field: 'vendor'},
        {label: 'Email', field: 'sender'},
        {label: 'Количество', field: 'amount'},
        {label: 'Цена', field: 'price'},
        {label: 'НДС', field: 'comments'},
        {label: 'Менеджер', field: 'manager'},
    ];


    get goods() {                                                           // Получаем из хранилища список товаров
        return readGoods(this.$store);
    }

    get allVendors() {                                                      // Получаем из хранилища список всех поставщиков
        return readAllVendors(this.$store);
    }

    get goods_count() {                                                     // Возвращаем количество найденных товаров 
        return this.$store.getters.goods.length
    }

    public created() {                                                      // На создание страницы
        const goodFilter = readGoodFilter(this.$store);                     // Получаем из хранилища goodFilter 
        if (goodFilter) {                                                   // и заполняем им форму
            this.tovar_search = goodFilter.tovar_search;
            this.is_gost = goodFilter.is_gost;
            this.is_tu = goodFilter.is_tu;
            this.is_only_2month = goodFilter.is_only_2month;
            this.vendors = goodFilter.vendors;
        }
        
        const rowsPerPage = readRowsPerPage(this.$store);                   // Получаем из хранилища сохраненное количество товаров на странице                    
        this.pagination.rowsPerPage = (rowsPerPage) ? rowsPerPage : 10      // Если меняли кол-во товаров, то ставим сохраненное, иначе - 10
    }

    public async mounted() {                                                
        await dispatchGetAllVendors(this.$store);                           // Получаем из базы и сохраняем в хранилище список всех поставщиков
        await this.search_goods()                                           // Ищем товары

        this.$eventBus.$on("switchCollapsed", () => {                       // Шина событий: на клик по кнопке Collapse
            setTimeout(this.recalcTDWidth, 500)                             // Пересчитываем ширины ячеек дополнительных таблиц
        });
    }
    
    public updated(){                                                       // На обновление страницы:
        this.recalcTDWidth();                                               // Пересчитываем ширины ячеек дополнительных таблиц
    }
    
    public async updatePagination (pagination) {                                // На изменение кол-ва товаров на странице:
        await dispatchUpdateRowsPerPage(this.$store, pagination.rowsPerPage);   // записываем в хранилище кол-во товаров на странице            
    }
    
    public async search_goods() {                                           // на кнопку "Искать"
        const goodFilter: IGoodFilter = {};                                 // получаем шаблон фильтра
                                                                            
        goodFilter.tovar_search = this.tovar_search;                        // запихиваем в шаблон данные из формы
        goodFilter.is_gost = this.is_gost;
        goodFilter.is_tu = this.is_tu;
        goodFilter.is_only_2month = this.is_only_2month;
        goodFilter.vendors = this.vendors;

        await dispatchUpdateGoodFilter(this.$store, goodFilter);            // Сохраняем в хранилище данные из формы
    }

    public recalcTDWidth() {                                                // Пересчет ширины ячеек дополнительных таблиц
        for (let i = 0; i < 10; i++){
            if ('td' + i in this.$refs) {
                this.td_width[i] = this.$refs['td'+i].clientWidth
            }
        }
    }

}
